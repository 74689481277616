import { } from 'admin/clients/show-edit';
import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Client, ClientHealthAndRepositories, Health, MyHttpApi, Repository } from 'utils/api';
import { Notify } from 'utils/notify';
import { RepositoryUtil, UpdateWindow } from 'utils/repository-util';

@autoinject
export class AdminClientsShowVersion {
  private repositoryList: Repository[] = [];
  private currentRepository?: Repository;
  private targetRepository?: Repository;
  private nextMinorRepository?: Repository;
  private canUpgradeClient = false;
  private clientHealthAndRepositories?: ClientHealthAndRepositories;
  private updateWindowList: UpdateWindow[] = [];
  private client?: Client;
  private updateWillHappen?: Date;
  private selectedRepository?: Repository;
  private health?: Health;

  constructor(private notify: Notify, private router: Router, private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id: string; }) {
    let clientId = parseInt(params.id)
    this.notify.unitId = clientId;
    await this.refresh(clientId);
  }

  async refresh(clientId: number) {
    [this.repositoryList, this.clientHealthAndRepositories] = await Promise.all([
      this.api.repositoryList({ archived: false, onlyStable: false }),
      this.api.clientClientHealthAndRepositoriesByClientId({ id: clientId }),
    ]);
    
    this.health = this.clientHealthAndRepositories.health;
    this.canUpgradeClient = this.clientHealthAndRepositories.canUpgradeClient;
    this.currentRepository = this.clientHealthAndRepositories.currentRepository;
    this.targetRepository = this.clientHealthAndRepositories.targetRepository;
    this.nextMinorRepository = this.clientHealthAndRepositories.nextMinorRepository;
    this.updateWillHappen = this.clientHealthAndRepositories.nextUpdate;
    this.updateWindowList = RepositoryUtil.updateWindowList(this.clientHealthAndRepositories.updateWindowList);
    this.client = this.clientHealthAndRepositories.client;
  }

  async isUpgradeSafe(repository: Repository) {
    if (this.clientHealthAndRepositories?.isUpgradeSafe && !RepositoryUtil.wouldDowngrade(repository, this.clientHealthAndRepositories.currentRepository)) {
      this.selectedRepository = undefined;
      await this.upgrade(repository);
      return;
    }
    this.selectedRepository = repository;
  }

  resetUpgrade() {
    this.selectedRepository = undefined;
  }

  async upgrade(repository: Repository) {
    if (!this.client) {
      return;
    }
    let clientId = this.client.id;
    let repositoryId = repository.id;
    let currentRepositoryId = this.currentRepository?.id;
    await this.api.clientUpgrade({ clientId, repositoryId, currentRepositoryId });
    this.notify.info("client.upgraded");
    await this.refresh(this.client.id);
    this.resetUpgrade();
  }

  async startUpdate(repository?: Repository) {
    if (repository) {
      await this.isUpgradeSafe(repository);
    } else {
      this.notify.info("client.noStablesAvailable");
    }
  }

  async toggleCanUpdateNextPatch() {
    if (!this.client) {
      return;
    }

    this.client.targetBranch = (await this.api.clientToggleCanUpdateNextPatch({ id: this.client.id })).targetBranch;
    await this.refresh(this.client.id);
  }

  @computedFrom("client.targetBranch", "currentRepository.branch")
  get targetBranch() {
    return this.client?.targetBranch && this.client.targetBranch.localeCompare(this.currentRepository?.branch || "", undefined, { numeric: true }) > 0
      ? this.client.targetBranch
      : "";
  }
}
