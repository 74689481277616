// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sshModal div.modal-content div.modal-box {
  border: 1px solid white;
  padding: 0;
  overflow-y: hidden;
}
#ssh {
  height: 90vh;
}
div.modal-content {
  overflow: hidden !important;
}
`, "",{"version":3,"sources":["webpack://./src/admin/clients/show-home.less"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,UAAA;EACA,kBAAA;AACF;AAEA;EACE,YAAA;AAAF;AAGA;EACE,2BAAA;AADF","sourcesContent":["#sshModal div.modal-content div.modal-box {\n  border: 1px solid white;\n  padding: 0;\n  overflow-y: hidden;\n}\n\n#ssh {\n  height: 90vh;\n}\n\ndiv.modal-content {\n  overflow: hidden !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
