import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BusinessGroup, EmailPurpose, EmailTemplateUpdateRequest, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class AdminEmailTemplatesEdit {
  private businessGroupList: BusinessGroup[] = [];
  private systemSpecificPurposes: EmailPurpose[] = [ // Copied from EmailTemplateDAO manually...
    "AccountingFailedToGenerate",
		"AccountingInTransit",
		"BillingFailedToGenerate",
		"BillingInTransit",
		"BillingUncompleted",
    "ForgotPassword",
    "InitOtp",
    "NewUserWelcome",
  ];

  private bilingualPurposes: EmailPurpose[] = [
    "NewUserWelcome",
  ];

  private bookkeepingNotifierSpecificPurposes: EmailPurpose[]
    = this.systemSpecificPurposes.filter( x => x.startsWith("Accounting") || x.startsWith("Billing"));
  private email: EmailTemplateUpdateRequest = {
    purpose: "GiftCardEmailDelivery",
    language: "FI",
    subject: "",
    text: "<p></p>",
    delete: false,
  };

  constructor(private router: Router, private api: MyHttpApi, private i18n: I18N, private notify: Notify) {}

	@computedFrom("email.purpose")
	get isGiftCardPurpose() {
		return this.email.purpose === "GiftCardEmailDelivery" || this.email.purpose === "GroupedGiftCardEmailDelivery"
	}

  async activate(params: { id?: string }) {
    this.businessGroupList = await this.api.businessGroupList();
    if (params.id) {
      let tmp = await this.api.emailTemplateById({ id: parseInt(params.id), asUri: true });
      this.email = {...tmp, delete: false};
    }
  }

  async save(deleted: boolean) {
    if (!this.email) {
      return;
    }
    if (this.email.language === "BI" && !this.bilingualPurposes.includes(this.email.purpose)) {
      this.notify.info(this.i18n.tr("server.emailPurposeNotBilingual"))
      return;
    }
    await this.api.emailTemplateUpdate({
      ...this.email,
      delete: deleted,
    });
    this.router.navigateToRoute("admin/email-templates/list");
  }
}
