import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, EmailTemplate, MyHttpApi } from 'utils/api';

@autoinject
export class AdminEmailTemplatesList {
  private list: EmailTemplate[] = [];
  private businessGroups: { [key: number]: BusinessGroup } = {};

  private fieldList: FieldSpec[] = [
    { key: "purpose", header: "emailTemplate.purpose", type: "enum", enum: "EmailPurpose", },
    { key: "businessGroupId", header: "emailTemplate.businessGroup", type: "lookup", lookupData: this.businessGroups, lookupKey: "name", },
    { key: "language", header: "emailTemplate.language", type: "enum", enum: "EmailTemplateLanguage" },
    { key: "subject", header: "emailTemplate.subject", type: "text" },
  ];

  constructor(private router: Router, private api: MyHttpApi) {
  }

  async activate() {
    let businessGroupList = await this.api.businessGroupList();
    for (let bg of businessGroupList) {
      this.businessGroups[bg.id] = bg;
    }
    await this.refresh();
  }

  async refresh() {
    this.list = await this.api.emailTemplateList();
  }
  
  select(et: EmailTemplate) {
    this.router.navigateToRoute("admin/email-templates/edit", { id: et.id });
  }
}
