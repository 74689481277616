import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BusinessGroup, Client, ClientHealthAndRepositories, ClientInfo, ClientModule, Corporation, Health, ModuleType, MyHttpApi, Repository } from 'utils/api';
import { HealthStatusResponse, HealthUtil } from 'utils/health-util';
import { Notify } from 'utils/notify';
import { RepositoryUtil, UpdateWindow } from 'utils/repository-util';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import env from '../../../config/environment.json';

@autoinject
export class AdminClientsShowHome {
  private clientId = 0;
  private client?: Client;
  private info?: ClientInfo;
  private health?: Health;
  private moduleList: ClientModule[] = [];
  private moduleType?: ModuleType;
  private currentRepository?: Repository;
  private targetRepository?: Repository;
  private nextMinorRepository?: Repository;
  private canUpgradeClient = false;
  private clientHealthAndRepositories?: ClientHealthAndRepositories;
  private updateWindowList: UpdateWindow[] = [];
  private updateWillHappen?: Date;
  private selectedRepository?: Repository;
  private businessGroup?: BusinessGroup;
  private corporation?: Corporation;
  private remoteControlUrl = "";
  private status: HealthStatusResponse = { message: "" };
  private showStatusModal = false;

  private showSocketModal = false;
  /* web ssh implementation */
  @observable({ changeHandler: "sshEnd" })
  private ssh = false;
  private terminal?: Terminal;
  private terminalFit?: FitAddon;
  private socket?: WebSocket;

  private refreshTimeout?: NodeJS.Timeout;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  private moduleTypes: ModuleType[] = Object.keys(this.i18n.i18next.getDataByLanguage(this.i18n.getLocale()).translation["ModuleType"]);

  constructor(private notify: Notify, private router: Router, private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id: string; }) {
    this.notify.unitId = this.clientId = parseInt(params.id);
    this.remoteControlUrl = env.server + "RemoteControl/view/" + params.id + "/";
    await this.refresh();
  }

  deactivate(): void {
    this.cancelTimer();
  }

  cancelTimer() {
    if (this.refreshTimeout) {
      clearInterval(this.refreshTimeout);
      this.refreshTimeout = undefined;
    }
  }

  async refresh() {
    [this.moduleList, this.info] = await Promise.all([
      this.api.clientListModule({ id: this.clientId }),
      this.api.clientInfoById({ id: this.clientId }),
    ]);

    this.clientHealthAndRepositories = await this.refreshHealth();
    this.client = this.clientHealthAndRepositories.client;
    if (this.client.businessGroupId) {
      this.businessGroup = await this.api.businessGroupById({ id: this.client.businessGroupId });
    }
    if (this.businessGroup?.corporationId) {
      this.corporation = await this.api.corporationById({ id: this.businessGroup.corporationId });
    }

    this.updateWindowList = RepositoryUtil.updateWindowList(this.clientHealthAndRepositories.updateWindowList);
  }

  @computedFrom("moduleTypes", "moduleList")
  get availableModules() {
    return this.moduleTypes
      .filter(mt => !this.moduleList.map(ml => ml.moduleType).includes(mt))
      .map(mt => {
        return {
          id: mt,
          name: this.i18n.tr("ModuleType." + mt),
        };
      });
  }

  async refreshHealth() {
    this.cancelTimer();
    let clientHealthAndRepositories = await this.api.clientClientHealthAndRepositoriesByClientId({ id: this.clientId });
    this.canUpgradeClient = clientHealthAndRepositories.canUpgradeClient;
    this.health = clientHealthAndRepositories.health;
    if (this.health) {
      this.status = HealthUtil.generateStatusAndWarnings(this.i18n, { ...clientHealthAndRepositories });
    }
    this.currentRepository = clientHealthAndRepositories.currentRepository;
    this.targetRepository = clientHealthAndRepositories.targetRepository;
    this.nextMinorRepository = clientHealthAndRepositories.nextMinorRepository;
    this.updateWillHappen = clientHealthAndRepositories.nextUpdate;
    this.refreshTimeout = setInterval(() => this.refreshHealth(), 30000);
    return clientHealthAndRepositories;
  }

  showStatusMessage() {
    this.showStatusModal = !!this.status.message;
  }

  @computedFrom("health.warningReasons")
  get warningReason() {
    return this.health?.warningReasons?.map(x => this.i18n.tr("WarningReason." + x)).join(", ");
  }

  async isUpgradeSafe(repository: Repository) {
    if (this.clientHealthAndRepositories?.isUpgradeSafe && !RepositoryUtil.wouldDowngrade(repository, this.clientHealthAndRepositories.currentRepository)) {
      this.selectedRepository = undefined;
      await this.upgrade(repository);
      return;
    }
    this.selectedRepository = repository;
  }

  resetUpgrade() {
    this.selectedRepository = undefined;
  }

  async upgrade(repository: Repository) {
    if (!this.client) {
      return;
    }
    let clientId = this.client.id;
    let repositoryId = repository.id;
    let currentRepositoryId = this.currentRepository?.id;
    await this.api.clientUpgrade({ clientId, repositoryId, currentRepositoryId });
    this.notify.info("client.upgraded");
    await this.refresh();
    this.resetUpgrade();
  }

  async startUpdate(repository?: Repository) {
    if (repository) {
      await this.isUpgradeSafe(repository);
    } else {
      this.notify.info("client.noStablesAvailable");
    }
  }

  async addModule() {
    if (!this.moduleType) {
      return;
    }
    await this.api.clientAddModule({ id: this.clientId, moduleType: this.moduleType });
    this.notify.info("client.moduleAdded");
    await this.refresh();
  }

  async deleteModule(module: ClientModule) {
    await this.api.clientDeleteModule({ id: module.id });
    this.notify.info("client.moduleDeleted");
    await this.refresh();
  }

  async sshStart() {
    let dom = document.getElementById("ssh");
    if (!dom) {
      return;
    }

    this.sshEnd();
    this.ssh = true;

    this.terminal = new Terminal();
    this.terminalFit = new FitAddon();
    this.terminal.loadAddon(this.terminalFit);
    this.terminal.open(dom);
    this.terminal.onData(msg => this.socket?.readyState == 1 && this.socket?.send(`in ${msg}`));
    this.terminal.onResize(msg => this.socket?.readyState == 1 && this.socket?.send(`size ${msg.cols} ${msg.rows}`));
    setTimeout(() => {
      this.terminal?.focus();
    }, 1);

    this.socket = new WebSocket(env.wsServer + this.client?.ipAddress);
    this.socket.onopen = () => {
      if (this.terminal) {
        this.terminal.writeln("Communication bridge opened.");
        this.sshResize();
        this.terminal.writeln(`Set window size to ${this.terminal.cols} × ${this.terminal.rows}`);
      }
    };
    this.socket.onmessage = (msg: MessageEvent<string>) => this.terminal?.write(msg.data);
    this.socket.onerror = (e) => this.terminal?.writeln("Error: " + e);
    this.socket.onclose = () => this.terminal?.writeln("Communication bridge closed.");
  }

  sshResize() {
    this.terminalFit?.fit();
    // If connection has not been established when performing initial resize the new dimensions are not delivered to server
    // Also, onResize() does not trigger on fit() if dimensions do not actually change
    // This way we can make sure the new dimensions are always forwarded
    this.socket?.readyState == 1 && this.socket?.send(`size ${this.terminal?.cols} ${this.terminal?.rows}`);
  }

  sshEnd() {
    if (this.terminalFit) {
      this.terminalFit.dispose();
      this.terminalFit = undefined;
    }
    if (this.terminal) {
      this.terminal.dispose();
      this.terminal = undefined;
    }
    if (this.socket) {
      this.socket.close();
      this.socket = undefined;
    }
  }
}
