import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Corporation, MyHttpApi } from 'utils/api';

@autoinject
export class AdminCorporationsList {
  private corporations2Groups: { [key: number]: { name: string; }; } = {};
  private corporationList: Corporation[] = [];
  private fields: FieldSpec[] = [
    { header: "corporation.name", key: "name", type: "text", },
    { header: "corporation.groups", key: "id", type: "lookup", lookupData: this.corporations2Groups, lookupKey: "name", },
  ];

  constructor(private api: MyHttpApi, private i18n: I18N, private router: Router) {
    this.refresh();
  }

  rowCall(key: string, row: Corporation) {
    if (row.id) {
      this.router.navigateToRoute("admin/corporations/edit", { id: row.id });
    }
  }

  async refresh() {
    let [grouplist, businessGroupList] = await Promise.all([
      this.api.corporationList(),
      this.api.businessGroupList(),
    ]);
    for (let b of businessGroupList) {
      let corporationId = b.corporationId;
      if (!corporationId) {
        continue;
      }
      if (!(corporationId in this.corporations2Groups)) {
        this.corporations2Groups[corporationId] = { name: '' };
      }
      if (this.corporations2Groups[corporationId].name) {
        this.corporations2Groups[corporationId].name += ", ";
      }
      this.corporations2Groups[corporationId].name += b.name;
    }
    for (let g of grouplist) {
      let groupId = g.id;
      if (!(groupId in this.corporations2Groups)) {
        this.corporations2Groups[groupId] = { name: "–" };
      }
    }
    this.corporationList = grouplist;
  }
}
