import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { FormatShortDateValueConverter } from 'resources/value-converters/format-short-date';
import { FormatSizeValueConverter } from 'resources/value-converters/format-size';
import { BusinessGroup, Client, Health, MyHttpApi } from 'utils/api';
import { ClientAbnormalState, HealthUtil } from 'utils/health-util';
import { Notify } from 'utils/notify';

type ClientAndHealth = Client & Health;

interface UIHealth extends ClientAndHealth {
  backup?: string;
  belServerAndSerial?: string;
  CPUAndProc?: string;
  days?: number;
  diskAndRAM?: string;
  nameAndIp?: string;
  status?: ClientAbnormalState;
  errorMessage: string;
  uptimeString?: string;
  version?: string;
  zipAndCity?: string;
  rowClass?: ClientAbnormalState;
  updateTarget?: string;
  hidden: boolean;
}

@autoinject
export class AdminClientsList {
  private defaults: { [key: string]: string; } = { "hidden": " " };
  private error?: string;
  private businessGroups: { [key: number]: BusinessGroup; } = {};
  private fields: FieldSpec[] = [
    { header: "common.name", key: "nickname", type: "text", },
    { header: "client.location", key: "zipAndCity", type: "text" },
    { header: "client.nameAndIp", key: "nameAndIp", type: "text", cssClass: "prewrapped" },
    { header: "businessGroup.businessGroup", key: "businessGroupId", type: "lookup", lookupData: this.businessGroups, lookupKey: "name", },
    { header: "client.state", key: "state", type: "enum", "enum": "HealthState" },
    { header: "client.error", key: "status", type: "text", cssClass: "link white" },
    { header: "client.version", key: "version", type: "text" },
    { header: "client.updateTarget", key: "updateTarget", type: "text", cssClass: "prewrapped" },
    { header: "client.belServerAndSerial", key: "belServerAndSerial", type: "text", cssClass: "prewrapped" },
    { header: "client.backup", key: "backup", type: "text", cssClass: "prewrapped" },
    { header: "client.automaticUpdate", key: "targetBranch", type: "text" },
    { header: "client.hidden", key: "hidden", type: "boolean" },
  ];
  private formatShortDate = new FormatShortDateValueConverter;
  private formatSize = new FormatSizeValueConverter;

  private refreshTimeout?: NodeJS.Timeout;
  private backendList: UIHealth[] = [];

  private alarms = 0;
  private errors = 0;
  private downs = 0;
  private hiddens = 0;

  constructor(private api: MyHttpApi, private i18n: I18N, private router: Router, private notify: Notify) {
    this.refresh();
  }

  rowCall(key: string, row: UIHealth) {
    if (key === "status") {
      if (!row.errorMessage) {
        return;
      }
      this.error = row.errorMessage;
    } else {
      this.notify.unitId = row.id;
      this.router.navigateToRoute("admin/clients/show-home", { id: "" + row.id });
    }
  }

  async activate() {
    if (this.api.session?.canBelAdmin) {
      this.fields.push({ header: "client.uptime", key: "uptimeString", type: "text", cssClass: "prewrapped" },);
      this.fields.push({ header: "client.diskAndRAM", key: "diskAndRAM", type: "text", cssClass: "prewrapped" },);
      this.fields.push({ header: "client.CPUAndProc", key: "CPUAndProc", type: "text", cssClass: "prewrapped" },);
    }
    this.notify.unitId = undefined;
    this.refreshTimeout = setInterval(() => this.refresh(), 60000);
    const businessGroupList = await this.api.businessGroupList();
    for (let bg of businessGroupList) {
      this.businessGroups[bg.id] = bg;
    }
  }

  deactivate(): void {
    if (this.refreshTimeout) {
      clearInterval(this.refreshTimeout);
      this.refreshTimeout = undefined;
    }
  }

  async refresh() {
    let chrList = await this.api.clientClientHealthAndRepositories();
    this.backendList = chrList.map(chr => {
      /* In theory, health should be always be there...  */
      let be: UIHealth = { ...chr.health!, ...chr.client, errorMessage: "" };
      if (chr.targetRepository && chr.canUpgradeClient) {
        // Bring also "canUpgradeClient" calculation here.... or to SS
        be.updateTarget = chr.targetRepository.branch + "-\n" + chr.targetRepository.abbreviatedSha;
        if (chr.nextUpdate) {
          be.updateTarget += "\n\n" + this.formatShortDate.toView(chr.nextUpdate);
        }
      }
      be.zipAndCity = be.zip + " " + be.city;
      be.belServerAndSerial = (be.belServer ?? "") + "\n" + (chr.serialNumber ?? "");
      be.nameAndIp = be.name + "\n" + (be.ipAddress ?? "");
      const health = chr.health;
      if (health) {
        be.days = health.uptime && Math.round(parseInt(health.uptime[0]) / 86400);
        if (health.loadavg[3] != undefined && health.loadavg[2] != undefined) {
          let tasks = health?.loadavg[3];
          let cpu = health.loadavg && parseFloat(health.loadavg[2]);
          be.CPUAndProc = cpu + "\n" + tasks;
        }

        let rootdisk = chr.disks.find(x => x.type === "ext4" || x.type === "btrfs") || chr.disks[0];
        let memtotal = chr.memory?.memTotal && parseInt(chr.memory.memTotal) * 1000;
        let memAvailable = chr.memory?.memAvailable && parseInt(chr.memory?.memAvailable) * 1000;
        let memfree = (memtotal || 0) - (memAvailable || 0);
        let rootDiskUsed = (rootdisk?.total || 0) - (rootdisk?.unallocated || 0);
        if (rootDiskUsed || chr.memory) {
          be.diskAndRAM = this.formatSize.toView(rootDiskUsed) + " / " + this.formatSize.toView(rootdisk?.total || 0)
          + "\n" + this.formatSize.toView(memfree) + " / " + this.formatSize.toView(memtotal || 0);
        }

        if (health.branch && health.abbreviatedSha) {
          be.version = health.branch + "-" + health.abbreviatedSha;
        }
        be.uptimeString = this.formatShortDate.toView(health.modifyTime);
        be.uptimeString += be.days ? "\n" + be.days + " pv" : "";
        const healthStatusResponse = HealthUtil.generateStatusAndWarnings(this.i18n, { ...chr });
        be.status = healthStatusResponse.status;
        be.errorMessage = healthStatusResponse.message;
      }
      be.backup = this.formatShortDate.toView(be.lastBackupTime) + "\n" + this.formatSize.toView(be.lastBackupSize);

      be.rowClass = be.status;
      return be;
    });
    this.errors = this.backendList.filter(be => !be.hidden && be.status === "error").length;
    this.downs = this.backendList.filter(be => !be.hidden && be.status === "down").length;
    this.alarms = this.backendList.filter(be => !be.hidden && be.status === "alarm").length;
    this.hiddens = this.backendList.filter(be => be.hidden).length;
  }
}
