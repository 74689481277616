import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BusinessGroup, Client, ClientInfo, ClientSaveUpdateWindowRequest, ClientUpdateRequest, Corporation, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';
import { RepositoryUtil, UpdateWindow } from 'utils/repository-util';

export const psqlMin = 128;
export const javaMin = 512;

@autoinject
export class AdminClientsShowEdit {
  private client?: Client;
  private info?: ClientInfo;
  private updateWindowList: UpdateWindow[] = [];
  private businessGroup?: BusinessGroup;
  private corporation?: Corporation;
  private configurableMemory = 2000;
  public readonly psqlMin = psqlMin;
  public readonly javaMin = javaMin;

  constructor(private notify: Notify, private router: Router, private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id: string; }) {
    this.notify.unitId = parseInt(params.id);
    await this.refresh(this.notify.unitId);
  }

  async refresh(clientId: number) {
    const [clientHealthAndRepositories, info, memory] = await Promise.all([
      this.api.clientClientHealthAndRepositoriesByClientId({ id: clientId }),
      this.api.clientInfoById({ id: clientId }),
      this.api.clientHealthMemoryByClientId({ id: clientId }),
    ]);
    this.info = info;

    this.client = clientHealthAndRepositories.client;
    if (this.client.businessGroupId) {
      this.businessGroup = await this.api.businessGroupById({ id: this.client.businessGroupId });
    }
    if (this.businessGroup?.corporationId) {
      this.corporation = await this.api.corporationById({ id: this.businessGroup.corporationId });
    }

    this.updateWindowList = RepositoryUtil.updateWindowList(clientHealthAndRepositories.updateWindowList);
    /* Leave 2GB for system to run, rest can be allocated freely */
    let totalMemory = 4000;
    if (memory) {
      totalMemory = Math.floor(parseInt(memory.memTotal) / 1000);
    }
    this.configurableMemory = totalMemory - 2000;
  }

  @computedFrom("configurableMemory", "client.psqlMemory")
  get javaLimit() {
    return Math.max(this.javaMin, this.configurableMemory - (this.client?.psqlMemory || 128));
  }

  @computedFrom("configurableMemory", "client.javaMemory")
  get psqlLimit() {
    return Math.max(this.psqlMin, this.configurableMemory - (this.client?.javaMemory || 512));
  }

  async save() {
    if (!this.client) {
      return;
    }

    let clientUpdateRequest: ClientUpdateRequest = {
      id: this.client.id,
      nickname: this.client.nickname,
      zip: this.client.zip,
      city: this.client.city,
      startDate: this.client.startDate,
      canUpdateToCanary: this.client.canUpdateToCanary,
      onPremisesIpAddress: this.client.onPremisesIpAddress,
      updateJava: this.client.updateJava,
      updatePostgresql: this.client.updatePostgresql,
      updateNginxConf: this.client.updateNginxConf,
      updateServiceAndSocket: this.client.updateServiceAndSocket,
      hidden: this.client.hidden,
      info: this.info?.info,
      javaMemory: this.client.javaMemory,
      psqlMemory: this.client.psqlMemory,
    };

    let cuwList = this.updateWindowList.filter(w => w.startTime && w.endTime).map(w => ({ weekday: w.weekday, startTime: w.startTime!, endTime: w.endTime! }));
    let updateWindowRequest: ClientSaveUpdateWindowRequest = { clientId: this.client.id, updateWindowList: cuwList };
    await Promise.all([
      this.api.clientSaveUpdateWindow(updateWindowRequest),
      this.api.clientUpdate(clientUpdateRequest),
    ]);
    this.notify.info("common.saved");
    await this.refresh(this.client.id);
  }

  emptyUpdateWindow(uw: UpdateWindow) {
    uw.startTime = uw.endTime = undefined;
  }

  async deleteCustomer() {
    if (!this.notify.unitId) {
      return;
    }
    if (!confirm(this.i18n.tr("client.confirmDelete"))) {
      return;
    }
    await this.api.clientDelete({ id: this.notify.unitId });
    this.notify.info("client.deleted");
    this.router.navigateToRoute("admin/clients/list");
  }
}
