import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BackupMeta, Client, ClientCertificate, ClientHealthAndRepositories, ClientHttpsCertificate, ClientMigrationCanMigrateResponse, Health, MyHttpApi, PosInstanceFeature } from 'utils/api';
import { Notify } from 'utils/notify';
import { RepositoryUtil, UpdateWindow } from 'utils/repository-util';

type UpgradeableComponent = "java" | "postgresql";

@autoinject
export class AdminClientsShow {
  private clientId = 0;
  private client?: Client;
  private health?: Health;
  private canUpgradeClient = false;
  private upgradeableComponent?: UpgradeableComponent;
  private clientHealthAndRepositories?: ClientHealthAndRepositories;
  private updateWindowList: UpdateWindow[] = [];
  private showSocketModal = false;
  private showVpnModal = false;
  private openVpnCert?: ClientCertificate;
  private httpsCert?: ClientHttpsCertificate;
  private canMigrate: ClientMigrationCanMigrateResponse = {
    hasBillStation: false,
    amountPremultipied: true,
  };

  private showHttpsCertModal = false;

  private backupList: BackupMeta[] = [];
  private files?: FileList;

  private refreshTimeout?: NodeJS.Timeout;
  private systemDFeature?: PosInstanceFeature;

  constructor(private notify: Notify, private router: Router, private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id: string; }) {
    this.notify.unitId = this.clientId = parseInt(params.id);
    await this.refresh();
  }

  async refresh() {
    this.cancelTimer();

    const [chrAndCertificates, backupList, canMigrate] = await Promise.all([
      this.api.clientHealthAndRepositoriesWithCertificates({ id: this.clientId }),
      this.api.clientListBackups({ id: this.clientId }),
      this.api.posClientMigrationCanMigrate({ clientId: this.clientId }),
    ]);
    this.clientHealthAndRepositories = chrAndCertificates.chr;
    this.openVpnCert = chrAndCertificates.openvpnCertificate;
    this.httpsCert = chrAndCertificates.httpsCertificate;
    this.backupList = backupList;
    this.canMigrate = canMigrate;
    this.health = this.clientHealthAndRepositories.health;
    this.client = this.clientHealthAndRepositories.client;
    this.canUpgradeClient = this.clientHealthAndRepositories.canUpgradeClient;
    this.updateWindowList = RepositoryUtil.updateWindowList(this.clientHealthAndRepositories.updateWindowList);
    this.systemDFeature = this.health?.features?.find(x => x.startsWith('SYSTEMD_SOCKET'));
    this.refreshTimeout = setInterval(() => this.refresh(), 30000);
  }

  deactivate(): void {
    this.cancelTimer();
  }

  cancelTimer() {
    if (this.refreshTimeout) {
      clearInterval(this.refreshTimeout);
      this.refreshTimeout = undefined;
    }
  }

  // * Backup
  async backup() {
    if (await this.api.clientBackup({ id: this.clientId })) {
      this.notify.info("client.backuped");
    } else {
      this.notify.info("client.cantBackup");
    }
  }
  async restore(name: string) {
    await this.api.clientRestore({ id: this.clientId, name });
    this.notify.info("client.restored");
  }
  restoreFromFile() {
    const clientId = this.client?.id;
    if (!this.files || !this.files.length || !clientId) {
      return;
    }
    const file = this.files[0];
    let reader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reader.addEventListener("load", async _e => {
      if (typeof reader.result === "string") {
        let data = reader.result.replace(/^[^,]+,/, "");
        await this.api.clientRestoreFromFile({ id: clientId, data, });
      }
    });
    reader.readAsDataURL(file);
  }

  async updateNginxConf() {
    if (this.clientId) {
      await this.api.clientUpdateNginxConf({id: this.clientId})
      this.notify.info("client.upgraded");
    }
  }

  async updateServiceAndSocket() {
    if (this.clientId) {
      await this.api.clientUpdateServiceAndSocket({id: this.clientId})
      this.notify.info("client.upgraded");
    }
  }

  // * Java / PSQL update  
  async startComponentUpgrade(component: UpgradeableComponent) {
    if (this.clientHealthAndRepositories?.isUpgradeSafe) {
      this.resetComponentUpgrade();
      await this.upgradeComponent(component);
      return;
    }
    this.upgradeableComponent = component;
  }

  async upgradeComponent(component: UpgradeableComponent) {
    if (!this.client) {
      return;
    }
    switch (component) {
      case "java": await this.api.clientUpdateJava({ id: this.client.id }); break;
      case "postgresql": await this.api.clientUpdatePostgresql({ id: this.client.id }); break;
    }
    this.notify.info("client.upgraded");
    this.resetComponentUpgrade();
  }

  resetComponentUpgrade() {
    this.upgradeableComponent = undefined;
  }

  httpsCertStart() {
    this.showHttpsCertModal = true;
  }
  async httpsCertCreate() {
    if (!this.client) {
      return;
    }
    await this.api.clientReplaceHttpsCertificate({
      clientId: this.client.id,
    });
    this.showHttpsCertModal = false;
  }

  // * VPN 
  async vpnCertStart() {
    if (!this.client) {
      return;
    }
    if (this.clientHealthAndRepositories?.isUpgradeSafe) {
      this.showVpnModal = false;
      await this.vpnCertUpgrade();
      return;
    }
    this.showVpnModal = true;
  }
  async vpnCertUpgrade() {
    this.showVpnModal = false;
    if (!this.client) {
      return;
    }
    await this.api.clientRefreshVpn({
      clientId: this.client.id,
    });
  }
  resetVpnUpgrade() {
    this.showVpnModal = false;
  }

  // * SYSTEM-D
  async systemDSocketStart() {
    if (!this.client) {
      return;
    }
    if (this.clientHealthAndRepositories?.isUpgradeSafe) {
      this.showSocketModal = false;
      await this.systemDSocketUpgrade();
      return;
    }
    this.showSocketModal = true;
  }
  async systemDSocketUpgrade() {
    this.showSocketModal = false;
    if (!this.client) {
      return;
    }
    await this.api.clientUpdateToSocket({
      id: this.client.id,
    });
  }

  resetSystemDSocketUpgrade() {
    this.showSocketModal = false;
  }

  // * 1632 custom migration

  @computedFrom("canMigrate")
  get migrationShowStopper() {
    let issue: string | undefined;
    if (this.canMigrate?.currentlyOngoingMigration?.clientId) {
      const ts = this.canMigrate.currentlyOngoingMigration.createTime;
      issue = this.i18n.tr("client-migration.alreadyRunning", {
        clientId: this.canMigrate.currentlyOngoingMigration?.clientId,
        startTime: ts.toLocaleDateString("fi-FI") + " " + ts.toLocaleTimeString("fi-FI")
      } as {});
    } else if (!this.canMigrate.hasBillStation) {
      issue = this.i18n.tr("client-migration.missingBillHasStation",);
    } else if (this.canMigrate.amountPremultipied) {
      issue = this.i18n.tr("client-migration.hasAmountPremultiplied");
    }
    return issue;
  }

  async startMigration() {
    if (!this.canMigrate || this.migrationShowStopper) {
      return;
    }
    this.canMigrate = await this.api.posClientMigrationStart({ clientId: this.clientId });
    this.notify.info("client-migration.started");
  }

  @computedFrom("canMigrate")
  get canMarkAsCompleted() {
    const ongoing = this.canMigrate.currentlyOngoingMigration;
    return ongoing?.migrationFinishTime && !ongoing.finishTime && ongoing.clientId === this.clientId;
  }

  async completeMigration() {
    this.canMigrate = await this.api.posClientMigrationMarkFullyCompleted({ clientId: this.clientId });
  }
}
