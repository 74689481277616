import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BusinessGroupGiftCardDebtAccount, BusinessGroupUpdateRequest, Client, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

export interface UIBusinessGroupGiftCardDebtAccount {
  id: number;
  name: string;
  creditAccount?: number;
  debitAccount?: number;
}

@autoinject
export class AdminBusinessGroupsEdit {
  private clientList: Client[] = [];
  private clientBusinessGroup: UIBusinessGroupGiftCardDebtAccount[] = [];

  private businessGroup: BusinessGroupUpdateRequest = {
    delete: false,
    name: "",
    clientSpecificGiftCards: false,
    clientSpecificAliases: false,
    clientSpecificCategories: false,
    clientSpecificOrderMessages: false,
    clientSpecificPortions: false,
    clientSpecificRecipes: false,
    clientSpecificSalesChannels: false,
    clientSpecificStorageUnits: false,
    clientSpecificSubCategories: false,
    clientSpecificPricingGroups: "RESTRICT",
    clientSpecificProducts: "RESTRICT",
    clientSpecificSalesRestrictions: "RESTRICT",
    clientId: [],
    giftCardDebtAccounts: [],
  };

  constructor(private notify: Notify, private router: Router, private api: MyHttpApi) {
  }

  async activate(params: { id: string; }) {
    this.clientList = await this.api.clientList();
    if (params.id) {
      let id = parseInt(params.id);
      let [businessGroup, clientBusinessGroup, debtAccounts, info] = await Promise.all([
        this.api.businessGroupById({ id }),
        this.api.clientListByBusinessGroupId({ id }),
        this.api.businessGroupListGiftCardDebtAccountById({ id }),
        this.api.businessGroupInfoById({ id }),
      ]);
      this.businessGroup = {
        ...businessGroup,
        delete: !!businessGroup.deleteTime,
        clientId: [],
        giftCardDebtAccounts: [],
        info: info?.info,
      };
      this.clientBusinessGroup = clientBusinessGroup.map(cbg => {
        const account = debtAccounts.find(da => da.clientId === cbg.id);
        return {
          id: cbg.id,
          name: cbg.name,
          creditAccount: account?.creditAccount,
          debitAccount: account?.debitAccount,
        };
      });
    }
  }

  async save(deleted: boolean) {
    if (!this.businessGroup?.name) {
      return;
    }
    // Press the plus-button on save just in case somebody did forget to press it
    document.querySelectorAll("button.add").forEach(b => {
      let button = <HTMLButtonElement>b;
      button.click();
    });
    const clients = this.clientBusinessGroup.map(x => x.id);
    if (deleted && clients.length) {
      this.notify.info("businessGroup.removeUnits");
      return;
    }
    if (clients.length == 0 && !deleted) {
      this.notify.info("businessGroup.fillInUnits");
      return;
    }
    let debtAccounts: BusinessGroupGiftCardDebtAccount[] = this.clientBusinessGroup
      .filter(cbg => !!cbg.creditAccount && !!cbg.debitAccount)
      .map(cbg => {
        return {
          businessGroupId: this.businessGroup.id || 0, // Will be rewritten anyway @ server
          clientId: cbg.id,
          creditAccount: cbg.creditAccount!,
          debitAccount: cbg.debitAccount!,
        };
      });
    this.businessGroup.clientId = clients;
    this.businessGroup.delete = deleted;
    this.businessGroup.giftCardDebtAccounts = debtAccounts;

    await this.api.businessGroupUpdate(this.businessGroup);
    this.router.navigateBack();
  }

  @computedFrom("clientBusinessGroup")
  get dynamicClientList() {
    return this.clientList.filter(x => {
      if (this.clientBusinessGroup.some(cbg => cbg.id == x.id)) {
        return false;
      }
      if (!x.businessGroupId) {
        return true;
      }
      if (this.businessGroup.id && this.businessGroup.id === x.businessGroupId) {
        return true;
      }
    });
  }
}
