import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BusinessGroup, CorporationUpdateRequest, IdName, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class AdminCorporationsEdit {
  private clientId?: number;
  private businessGroupList: BusinessGroup[] = [];
  private businessGroupCorporation: IdName[] = [];

  private corporation: CorporationUpdateRequest = {
    delete: false, name: "", businessGroupId: []
  };

  constructor(private notify: Notify, private router: Router, private api: MyHttpApi) {
  }

  async activate(params: { id: string; }) {
    this.businessGroupList = await this.api.businessGroupList();
    if (params.id) {
      let id = parseInt(params.id);
      let [corporation, businessGroupCorporation, info] = await Promise.all([
        this.api.corporationById({ id }),
        this.api.businessGroupListByCorporationId({ id }),
        this.api.corporationInfoById({ id }),
      ]);
      this.corporation = {
        ...corporation,
        delete: !!corporation.deleteTime,
        businessGroupId: [],
        info: info?.info,
      };
      this.businessGroupCorporation = businessGroupCorporation;
    }
  }

  async save(deleted: boolean) {
    if (!this.corporation?.name) {
      return;
    }
    // Press the plus-button on save just in case somebody did forget to press it
    document.querySelectorAll("button.add").forEach(b => {
      let button = <HTMLButtonElement>b;
      button.click();
    });
    const groups = this.businessGroupCorporation.map(x => x.id);
    if (deleted && groups.length) {
      this.notify.info("businessGroup.removeUnits");
      return;
    }
    if (groups.length == 0 && !deleted) {
      this.notify.info("corporation.fillInGroups");
      return;
    }

    await this.api.corporationUpdate({
      id: this.corporation.id,
      name: this.corporation.name,
      delete: deleted,
      businessGroupId: groups,
      info: this.corporation.info,
    });
    this.router.navigateBack();
  }

  @computedFrom("businessGroupCorporation")
  get dynamicBusinessGroupList() {
    return this.businessGroupList.filter(x => {
      if (this.businessGroupCorporation.some(bgc => bgc.id == x.id)) {
        return false;
      }
      if (!x.corporationId) {
        return true;
      }
      if (this.corporation.id && this.corporation.id === x.corporationId) {
        return true;
      }
    });
  }
}
