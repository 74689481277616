import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, MyHttpApi } from 'utils/api';

@autoinject
export class AdminBusinessGroupsList {
  private businessGroup2Units: { [key: number]: { name: string; }; } = {};
  private businessGroupList: BusinessGroup[] = [];
  private fields: FieldSpec[] = [
    { header: "businessGroup.name", key: "name", type: "text", },
    { header: "businessGroup.units", key: "id", type: "lookup", lookupData: this.businessGroup2Units, lookupKey: "name", },
  ];

  constructor(private api: MyHttpApi, private i18n: I18N, private router: Router) {
  }
  
  async activate() {
    await this.refresh();
  }
  
  rowCall(key: string, row: BusinessGroup) {
    if (row.id) {
      this.router.navigateToRoute("admin/business-groups/edit", { id: row.id });
    }
  }

  async refresh() {
    let [grouplist, clientList] = await Promise.all([
      this.api.businessGroupList(),
      this.api.clientList(),
    ]);
    for (let c of clientList) {
      let groupId = c.businessGroupId;
      if (!groupId) {
        continue;
      }
      if (!(groupId in this.businessGroup2Units)) {
        this.businessGroup2Units[groupId] = { name: '' };
      }
      if (this.businessGroup2Units[groupId].name) {
        this.businessGroup2Units[groupId].name += ", ";
      }
      this.businessGroup2Units[groupId].name += c.nickname;
    }
    for (let g of grouplist) {
      let groupId = g.id;
      if (!(groupId in this.businessGroup2Units)) {
        this.businessGroup2Units[groupId] = { name: "–" };
      }
    }
    this.businessGroupList = grouplist;
  }
}
