import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ClientHealthAndRepositories, Health, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';


@autoinject
export class AdminClientsShowDevice {
  private clientId = 0;
  private clientHealthAndRepositories?: ClientHealthAndRepositories;
  private health?: Health;
  private refreshTimeout?: NodeJS.Timeout;

  constructor(private notify: Notify, private router: Router, private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id: string; }) {
    this.notify.unitId = this.clientId = parseInt(params.id);
    await this.refresh();
  }

  deactivate(): void {
    this.cancelTimer();
  }

  cancelTimer() {
    if (this.refreshTimeout) {
      clearInterval(this.refreshTimeout);
      this.refreshTimeout = undefined;
    }
  }

  async refresh() {
    this.cancelTimer();
    this.clientHealthAndRepositories = await this.api.clientClientHealthAndRepositoriesByClientId({ id: this.clientId });
    this.health = this.clientHealthAndRepositories.health;
    this.refreshTimeout = setInterval(() => this.refresh(), 30000);
  }
}
